import { Box, Typography } from "@mui/material";
import NavBar from "./Navbar";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailIcon from "@mui/icons-material/Mail";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";

const RefundPolicy = () => {
  return (
    <>
      <NavBar name="Refund Policy" />
      <Box
        style={{
          display: "flex",
          margin: "15px",
          marginTop: "20px",

          justifyContent: "center",
          flexDirection: "column",
          // alignItems: "center",
        }}
      >
        {/* <Typography variant="h4" color="primary">Privacy Policy</Typography> */}
        <Typography variant="subtitle2" color="primary">
          1. AJ Finance: Refund Policy
        </Typography>

        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          At AJ Finance, we value transparency and professionalism in all our
          dealings. As a Direct Selling Agent (DSA), the payments received by us
          are categorized as service charges for the assistance, guidance, and
          services provided by our team. To ensure clarity for our clients, this
          return policy outlines our stance on refunds, cancellations, and
          payment adjustments.
        </Typography>

        <Typography variant="subtitle2" color="primary">
          2. Nature of Payment
        </Typography>

        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          <span style={{color: '#620101'}}> 1.1 Service Charges : </span> Only- All payments made to AJ Finance are strictly
          categorized as service charges. These charges are for the time,
          effort, and expertise provided by our team to facilitate financial
          services for our clients. These charges are non-refundable under any
          circumstances, as they represent compensation for services rendered.
        </Typography>

        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}> 1.2 Non-Refundable Policy : </span>Once a payment is made to AJ Finance, it
          cannot be refunded, reversed, or adjusted against any other service or
          future transaction. This policy applies to all services offered by AJ
          Finance, including but not limited to documentation, application
          processing, consultation, or advisory services.
        </Typography>

        <Typography variant="subtitle2" color="primary">
          3. Why Payments Are Non-Refundable
        </Typography>

        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}>1.1 Effort-Based Model : </span> As a DSA, our team dedicates time and resources
          to process client applications, provide consultations, and coordinate
          with financial institutions. The payment made by clients reflects the
          cost of these efforts, which cannot be undone or reversed once the
          service has been initiated.{" "}
        </Typography>

        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}>1.2 Irretrievable Expenses : </span> Many costs incurred by AJ Finance in
          providing services are non-recoverable. This includes operational
          costs, documentation, communication with institutions, and the use of
          proprietary tools.
        </Typography>

        <Typography variant="subtitle2" color="primary">
          4. Transparency and Fair Practices
        </Typography>

        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}>1.1 Service Explanation  : </span> Before any payment is accepted, AJ Finance
          ensures that clients are fully informed about the nature of the
          service, the associated charges, and the non-refundable policy. A
          detailed receipt or acknowledgment is provided upon payment,
          explicitly stating the non-refundable nature of the charges.
        </Typography>

        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}>1.2 No Hidden Charges : </span> AJ Finance maintains transparency in its fee
          structure. All charges are communicated upfront to the client, and no
          additional hidden fees are imposed.
        </Typography>

        <Typography variant="subtitle2" color="primary">
          5. Exceptions and Special Cases
        </Typography>

        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}> 1.1 Refunds Not Applicable in Any Circumstances : </span> Refunds or
          cancellations will not be entertained even in the following
          situations: The client decides to cancel the service after payment.
          The financial institution rejects the client’s application. The client
          changes their mind about availing of the service. Payments are
          non-refundable regardless of the progress or outcome of the financial
          service being sought.
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}> 1.2 Defective Services Not Covered : </span> Since AJ Finance deals with
          intermediary services, the outcome of financial approvals depends on
          external institutions, and AJ Finance cannot be held liable for
          rejections or delays.
        </Typography>

        <Typography variant="subtitle2" color="primary">
          6. Client Responsibilities
        </Typography>

        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}> 1.1 Understanding the Terms : </span>It is the responsibility of the client to
          thoroughly understand this return policy before making any payments.
          By proceeding with a payment, clients confirm their agreement to these
          terms and conditions.
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px",textAlign: 'justify' }}>
        <span style={{color: '#620101'}}> 1.2 Accurate Information : </span>Clients must provide accurate and complete
          information while availing of AJ Finance’s services. Incorrect or
          incomplete data may lead to delays or rejections, and no refunds will
          be provided in such cases.
        </Typography>
      </Box>
    </>
  );
};

export default RefundPolicy;
