import { Button, Grid } from "@mui/material";
import { useEffect, useRef } from "react";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const items = [
  {
    url: "/images/das6.jpg",
  },
  {
    url: "/images/das5.png",
  },
  {
    url: "/images/das7.png",
  },
];

const SliderComponent = ({data}) => {

  const zoomImageRefs = useRef([]); // Use array to store refs for each image
  const history = useNavigate();
 
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scaleFactor = 1 - scrollPosition / 1000;

      zoomImageRefs.current.forEach((imageRef) => {
        if (imageRef) {
          if (scaleFactor > 0.5) {
            imageRef.style.transform = `scale(${scaleFactor})`;
          } else {
            imageRef.style.transform = `scale(0.5)`;
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
const handleButtonClick =()=>{
  if(data.length>0 && data[0].rejected==0){
    toast('Your loan request is still in the pending stage.', {
      className: "custom-toast",
    });
    // alert('Your loan request is still in the pending stage.')
  }else{
    history("/homeloanview")
  }
}

  return (
    <>

    
      <Button
        variant="contained"
        onClick={handleButtonClick}
        style={{
          top: "222px",
          right: "22px",
          position: "absolute",
          color: "#fff",
          background: "hsl(345deg 100% 47%)",
          cursor: "pointer",
          fontSize: "14px",
          zIndex: 10,
        }}
      >
        Apply Now
      </Button>

      <Carousel
        autoPlay
        animation="slide"
        duration={1400}
        indicators
        sx={{top: "80px",}}
        // sx={{marginTop: "65px", }}
      >

        {items.map((item, i) => (
          <div
            key={i}
            style={{ position: "relative", animation: "transform 2s ease-out" }}
          >
            <Grid
              container
              // direction="column"
              // sx={{marginTop: "65px", }}
              alignItems="center"
              justifyContent="center"
            >
                <img
                  ref={(el) => (zoomImageRefs.current[i] = el)} // Assign ref to each image
                  className="zoom-image"
                  width="95%"
                  height="190px"
                  style={{ borderRadius: "8px" }}
                  src={item.url}
                  alt={`carousel-item-${i}`}
                />
            </Grid>
          </div>
        ))}
      </Carousel>
      <ToastContainer/>
    </>
  );
};

export default SliderComponent;
