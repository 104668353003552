import {
  Avatar,
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SliderComponent from "./SliderComponent";
import "react-toastify/dist/ReactToastify.css";
import Divider from "@mui/material/Divider";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import LogoutIcon from '@mui/icons-material/Logout';
import HistoryIcon from '@mui/icons-material/History';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ArticleIcon from '@mui/icons-material/Article';
import HelpIcon from '@mui/icons-material/Help';
import PolicyIcon from '@mui/icons-material/Policy';
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import axios from "axios";
import { Fingerprint } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HomePage = () => {
  const history = useNavigate();
  // const [loginStatus, setLoginStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [loding, setLoding] = useState(false);
  const [data, setData] = useState([]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const userId = localStorage.getItem("userId");

  const fetchData = async (userId) => {
    try {
      const response = await axios
        .get(
          `https://api.ajfinance.in/api/function.php?action=loandata&mobile=${userId}`
        )
        .then((res) => {
          setData(res.data);
          if (res.data.length > 0 && res.data[0].pan_number == null) {
            localStorage.clear();
            // setLoginStatus(false);
            history("/login");
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchData(userId);
    if (userId) {
      history("/");
      // setLoginStatus(true);
    } else {
      localStorage.clear();
      // setLoginStatus(false);
      history("/login");
    }
  }, []);

  const logOut = () => {
    localStorage.clear();
    // setLoginStatus(false);
  };

  const handleButtonClick = () => {
    if (data.length > 0 && data[0].status == 0) {
      toast("Your loan request is still in the pending stage.", {
        className: "custom-toast",
        // style: customToastStyles,
      });
    } else {
      history("/homeloanview");
    }
  };
  const DrawerList = (
    <Box sx={{ width: 240, }} role="presentation" onClick={toggleDrawer(false)}>
      <Box
        style={{
          display: "flex",
          height: "100px",
          justifyContent: "space-between",
          padding: "0 5px",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            alt="Remy Sharp"
            src="/images/myimg.png"
            sx={{ width: 56, height: 56 }}
          />
          <Box ml={1}>
            <Typography
             color="primary"
              style={{  fontWeight: 700,fontSize: '15px' }}
            >
              {data.length > 0 && data[0].full_name}
            </Typography>
            <Typography
              fontSize="12px"
             
              align="left"
              style={{ lineHeight: 1,color: "#787a7a", }}
            >
              {data.length > 0 && data[0].mobile}
            </Typography>
            <Typography
              fontSize="12px"
              
              align="left"
              style={{ lineHeight: 1 ,color: "#787a7a",}}
            >
              {data.length > 0 && data[0].email_id}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <List>
        {[
          { name: "Loan history", addr: "/dds", img: <HistoryIcon/> },
          { name: "Help and Support", addr: "/help", img: <HelpIcon/> },
          { name: "Privacy Policy", addr: "/policy", img: <PolicyIcon/> },
          { name: "Refund Policy", addr: "/rpolicy", img: <AssignmentReturnIcon/> },
          { name: "Terms and conditions", addr: "/terms",img: <ArticleIcon/> },
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <Link
              to={text.addr}
              style={{ textDecoration: "none", color: "#787a7a" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  {text.img}
                </ListItemIcon>
                <ListItemText primary={text.name} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Link to="/login" style={{ textDecoration: "none" }}>
        <Box
          style={{
            borderColor: "#fff",
            color: "#787a7a",
            marginLeft: "16px",
            justifyContent: "flex-start",
            gap: 30,
            display: "flex",
          }}
          onClick={logOut}
        >
          <LogoutIcon color="#787a7a" />
          <Typography>Logout</Typography>
        </Box>
      </Link>
    </Box>
  );

  return (
    <>
      <div data-v-app="" style={{ fontSize: "12px" }}>
        <Box
          position="fixed"
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#028090",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            zIndex: 1000,
            height: "63px",
          }}
        >
          <Box style={{display:'flex'}}>
          <img
            src="/images/logo102.png"
            width="70px"
            height="60px"
            alt="logo"
            style={{ marginTop: "8px" }}
          />
          <Typography style={{ marginTop: "14px",color:'#fff',fontSize: '25px', fontFamily: 'fangsong' }} >Finance</Typography>
          </Box>
          {/* <Box style={{display:'flex'}}>
          <Typography style={{fontWeight: 800,
    fontSize: '32px',
    color: 'floralwhite'}}>AJ</Typography>
          <Typography>finance</Typography>
          </Box> */}
          <Box style={{ display: "flex", gap: 15 }}>
            <Box
              style={{ borderColor: "#fff", color: "#fff", padding: "0 12px" }}
              onClick={toggleDrawer(true)}
            >
              <FormatAlignRightIcon />
            </Box>

            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              {DrawerList}
            </Drawer>
          </Box>
        </Box>
        <Box
          sx={{
            marginBottom: "5px",
            width: "100%",
            // backgroundColor: "#704e86",
            backgroundColor: "#028090",
            height: "310px",
            zIndex: 900,
          }}
        >
          <SliderComponent data={data} />
        </Box>

        <Box
          style={{
            zIndex: 1950,
            padding: "5px 15px",
            // background: "#fcf8de",
          }}
        >
          <Typography
            color="primary.contrastText"
            style={{ fontSize: "14px", fontWeight: 600 }}
          >
            REPORT
          </Typography>
        </Box>

        <Paper
          elevation={2}
          style={{
            minHeight: "25px",
            padding: "4px",
            margin: "10px",
            // background: "#fcf8de",
          }}
        >
          {data.map((alldata, ind) => (
            <Grid
              gap={2}
              key={ind}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "5px 10px",
                width: "100%",
              }}
            >


                  {alldata.status == 4 ?(
                    <Box style={{gap: '6px'}}>
                      <Typography
                       
                        style={{ color: "#000",fontSize: '20px' }}
                      >
                        Sorry!!
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "#1cb0b6" }}
                      >
                        We Regrate to inform you that we are unable to process
                        your application as it does not meet our internal policy
                        criteria.
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "#b61c1d" }}
                      >
                        Please try again after 60 days.
                      </Typography>
                    </Box>
                  ):
                  
                 

              <Box
                style={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  background: "white",
                  justifyContent: "space-between",
                  minHeight: "20px",
                  borderRadius: "5px",
                  width: "100%",
                  padding: "4px",
                }}
              >
                <img
                  alt="Image"
                  src="/images/insurance.jpg"
                  width="70px"
                  height="45px"
                />
                <Box style={{ marginLeft: "-20px" }}>
                  <Typography variant="body1" fontWeight={700}>
                    {alldata.loan_type}
                  </Typography>
                  <Typography variant="subtitle1">
                    Amount :{alldata.loan_amount}
                  </Typography>
                </Box>
                <Box>
                  {alldata.status == 0 ? (
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      color="primary"
                    >
                      Loan Pending
                    </Typography>
                  ) : (<>
                   {alldata.status == 4 ? <Typography variant="subtitle1" color="primary">
                      Loan Rejected
                    </Typography>: 
                    <Typography variant="subtitle1" color="primary">
                      Loan Approved
                    </Typography>
                   }</>
                  )}

                  {alldata.status == 1 && (
                    <Link to={`https://payment.ajfinance.in/?mobile=${userId}`}>
                      <IconButton
                        aria-label="fingerprint"
                        color="primary"
                        style={{ padding: "5px 0", color: "#1cb0b6" }}
                      >
                        <Fingerprint />
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#1cb0b6" }}
                        >
                          Click here
                        </Typography>
                      </IconButton>
                    </Link>
                  )}

                  {alldata.status == 2 && (
                    <>
                      <IconButton
                        aria-label="fingerprint"
                        color="primary"
                        onClick={() => history("/bankdetails")}
                        style={{ padding: "5px 0", color: "#1cb0b6" }}
                      >
                        <Fingerprint />
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#1cb0b6" }}
                        >
                          Fill Bank details
                        </Typography>
                      </IconButton>
                    </>
                  )}

                  {alldata.status == 3 && (
                    <>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "#1cb0b6" }}
                      >
                        Please wait 48 hours
                      </Typography>
                    </>
                  )}

               {alldata.status == 4 && (
                    <>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "#1cb0b6" }}
                      >
                        Sorry!!
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
               }
            </Grid>
          ))}
        </Paper>

        <Box
          style={{
            padding: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="new"
        >
          <Typography
            color="primary.contrastText"
            style={{ fontSize: "14px", fontWeight: 600 }}
          >
            MY PRODUCTS
          </Typography>
        </Box>
        <Box
          style={{
            minHeight: "85px",
            padding: "10px",
          }}
        >
          <Grid
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "0 1px",
              width: "100%",
            }}
          >
            <Paper
              elevation={3}
              style={{
                display: "flex",
                alignItems: "center",
                background: "white",
                minHeight: "80px",
                borderRadius: "4px 0 0 4px",
                width: "100%",
                gap: 2,
              }}
            >
              <Box
                width="50%"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <img
                  alt="Image"
                  src="/images/test.png"
                  width="60px"
                  height="50px"
                />
                <Box pr={1}>
                  <Typography fontSize={11} fontWeight={700} color="primary">
                    CREDIT TRACKER
                  </Typography>
                  <Typography fontSize={14}>700 / 900</Typography>
                </Box>
              </Box>

              <Divider orientation="vertical" flexItem />

              <Box width="50%">
                <Typography fontSize={13} p={2} fontWeight={600}>
                  Last Updated: 10 Oct 2024
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Box>
        <div className="lined-text" style={{ marginTop: "20px" }}>
          <Typography
            color="primary.contrastText"
            style={{ fontSize: "18px", fontWeight: 600 }}
          >
            EXPLORE MORE
          </Typography>
        </div>
        <Box style={{ padding: "15px" }}>
          <Typography
            color="primary.contrastText"
            style={{ fontSize: "14px", fontWeight: 600 }}
          >
            LOANS
          </Typography>
        </Box>
        <Box
          style={{
            minHeight: "85px",
            padding: "4px",
          }}
        >
          <Grid
            gap={1}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "0 10px",
              width: "100%",
            }}
          >
            <Paper
              elevation={3}
              onClick={handleButtonClick}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                background: "white",
                minHeight: "100px",
                borderRadius: "10px",
                width: "42%",
                padding: "10px",
                gap: 5,
              }}
            >
              <Box
                style={{
                  display: "flex",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#595757" }}
                  fontSize={15}
                  fontWeight={700}
                  lineHeight={1.7}
                >
                  Personal Loan
                </Typography>
                <img
                  alt="Image"
                  src="/images/mort.jpg"
                  width="60px"
                  height="48px"
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  gap: 5,
                }}
              >
                <Typography fontSize={12}>
                  Get up to 5L without income proof
                </Typography>
                <ArrowRightAltIcon />
              </Box>
            </Paper>
            <Paper
              elevation={3}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                background: "white",
                minHeight: "100px",
                borderRadius: "10px",
                width: "42%",
                padding: "10px",
                gap: 5,
              }}
              onClick={handleButtonClick}
            >
              <Box
                style={{
                  display: "flex",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#595757" }}
                  fontSize={15}
                  lineHeight={1.7}
                  fontWeight={700}
                >
                  Business Loan
                </Typography>
                <img
                  alt="Image"
                  src="/images/Business-Loan-1.png"
                  width="80px"
                  height="60px"
                  style={{ marginLeft: "-10px" }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  gap: 5,
                }}
              >
                <Typography fontSize={11}>
                  {" "}
                  Get up to 75L without ITR
                </Typography>
                <ArrowRightAltIcon />
              </Box>
            </Paper>

            <Paper
              elevation={3}
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 25,
                alignItems: "center",
                background: "white",
                minHeight: "70px",
                borderRadius: "10px",
                width: "100%",
              }}
              onClick={handleButtonClick}
            >
              <img
                alt="Image"
                src="/images/hom.png"
                width="100px"
                // height="60px"
                style={{ padding: "5px" }}
              />
              <Box>
                <Typography
                  variant="body1"
                  sx={{ color: "#595757" }}
                  fontWeight={800}
                >
                  Home Loan
                </Typography>
                <Typography variant="subtitle1">
                  Starting from 8.5% p.a.
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Box>

        <ToastContainer />

        {/* <Box style={{ marginTop: "60px" }}>
          <FooterNav />
        </Box> */}
      </div>
    </>
  );
};
export default HomePage;
