import { Box, Typography } from "@mui/material";
import NavBar from "./Navbar";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailIcon from "@mui/icons-material/Mail";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <NavBar name="Terms & Conditions" />
      <Box
        style={{
          display: "flex",
          margin: "15px",
          marginTop: "20px",
          justifyContent: "center",
          flexDirection: "column",
          // alignItems: "center",
        }}
      >
        <Typography variant="subtitle2" color="primary">
          1. Introduction
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          Welcome to ajfinance.in. These Terms and Conditions govern your access
          and use of our website, services, and products. By using our platform,
          you agree to be bound by these terms. If you do not agree, please
          discontinue use immediately.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          2. Eligibility
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          The use of this website is intended for individuals who are 18 years
          of age or older. By accessing the website, you confirm that: You are
          at least 18 years old. You have the legal capacity to enter into a
          binding agreement. Your use complies with all applicable laws and
          regulations.
        </Typography>

        <Typography variant="subtitle2" color="primary">
          3. Use of Website
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          Users agree to use ajfinance.in responsibly and for lawful purposes
          only. Prohibited actions include, but are not limited to: Engaging in
          fraudulent activities. Posting or transmitting malicious content such
          as viruses. Infringing upon the rights of others.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          4. User Account
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          If you create an account, you are responsible for: Maintaining the
          confidentiality of your login credentials. Ensuring all information
          provided is accurate and up-to-date. Notifying us immediately in case
          of unauthorized access.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          5. Services Provided
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          ajfinance.in offers financial services, including but not limited to:
          Loan application and processing. Investment opportunities tailored to
          user needs. Financial planning resources and tools.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          6. Payment Terms
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          All payments made through our platform are subject to the following
          conditions: Payments must be made in full as per the terms displayed
          during the transaction. Refund policies are outlined under separate
          terms specific to each service. We reserve the right to modify payment
          terms at any time.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          7. Intellectual Property Rights
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          All content on ajfinance.in, including but not limited to text,
          images, logos, and code, is protected by intellectual property laws.
          Unauthorized use or reproduction is strictly prohibited.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          8. Limitation of Liability
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          Under no circumstances shall ajfinance.in be liable for any damages,
          including but not limited to: Loss of data or profits. Service
          interruptions or delays. Unauthorized access to user accounts.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          9. Indemnification
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          By using our website, you agree to indemnify and hold harmless
          ajfinance.in and its affiliates from any claims or damages resulting
          from your violation of these terms.
        </Typography>

        <Typography variant="subtitle2" color="primary">
          10. Termination
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          We reserve the right to terminate or suspend your access to
          ajfinance.in at any time without notice, especially in cases of:
          Violation of these terms. Engagement in fraudulent or harmful
          activities.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          11. Governing Law
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          These terms shall be governed by the laws of [Your Jurisdiction]. Any
          disputes arising from these terms will be resolved in the courts of
          [Your Jurisdiction].
        </Typography>
        <Typography variant="subtitle2" color="primary">
          12. Changes to Terms
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          We may update these Terms and Conditions periodically. Any changes
          will be communicated via our website or email notifications.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          13. Contact Information
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          If you have questions or concerns about these Terms and Conditions,
          contact us at: Email: help@ajfinance.in Phone:80 6939 0476 Address: B
          13 patel market misrod bhopal 462026
        </Typography>
      </Box>
    </>
  );
};

export default Terms;
