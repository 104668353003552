import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";


const LoginForm = () => {
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const history = useNavigate();
  // useEffect(() => {
  //   const userId = localStorage.getItem("userId");
  //   if (userId) {
  //     history("/");
  //   }
  // }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Check if required fields are filled and meet the minimum length requirement
    if (!data.username || data.username.length < 10) {
      toast("Fill at least 10 characters", { className: "custom-toast" });
      return;
    }

    if (!data.password) {
      toast("Please fill in your password", { className: "custom-toast" });
      return;
    }
    const datas = new FormData();
    datas.append("mobileno", data.username);
    datas.append("password", data.password);

    try {
      const response = await axios
        .post(
          "https://api.ajfinance.in/api/function.php?action=register",
          datas
        )
        .then((res) => {
          if (res.data[0].status === "success" || "User already exists") {
           
            if(res.data[0].ststusapp === 4){
              history("/");
              localStorage.setItem("userId", data.username);
            }else if(res.data[0].ststusapp === 2){
              history("/apply");
              localStorage.setItem("userId", data.username);
            }else{
              history("/apply");
              localStorage.setItem("userId", data.username);
            }
            

          } else {
            toast(res.data.message, {
              className: "custom-toast",
            });
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography component="h1" variant="h5" fontWeight={600}>
            Welcome !
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="Mobile Number"
              autoFocus
              name="username"
              placeholder="Enter your mobile number"
              type="number"
              id="username"
              onChange={handleChange}
              autoComplete="off"
              error={data.username.length > 0 && data.username.length < 10} // Add error prop
              helperText={
                data.username.length > 0 && data.username.length < 10
                  ? "Must be at least 10 characters"
                  : ""
              }
            />
            <TextField
              margin="normal"
              value={data.password}
              required
              fullWidth
              label="Password"
              onChange={handleChange}
              name="password"
              placeholder="Enter your password"
              type="password"
              id="password"
            />

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" checked />}
              label="Send me updates over WhatsApp"
              sx={{ color: "#989898" }}
            />

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 1,
                mt: 3,
              }}
            >
              <Checkbox
                checked
                value="term"
                color="primary"
                sx={{ m: 0, padding: 0, ml: "-2px" }}
              />
              <Typography sx={{ color: "#989898" }}>
                I accept the Terms of Service & Privacy Policy, which includes
                permission to securely share my data/documents with verified
                partners to process my application.
              </Typography>
            </Box>

            <Typography fontSize={13} sx={{ color: "#989898" }}></Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, color: "#fff", fontSize: "18px" }}
            >
              Sign In
            </Button>
            <ToastContainer />

            <Grid container></Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};
export default LoginForm;
