import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";
import NavBar from "./Navbar";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRedius: "10px",
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
};

const steps = ["1", "2", "3"];
const ApplyForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    fullname: "",
    employeetype: "Salaried",
    income: "",
    loantype: "Personal Loan",
    dob: "",
    pancard: "",
    adharcard:'',
    fathername:'',
    company: "",
    email: "",
    loanamount: "",
    pincode: "",
  });
  const [errors, setErrors] = useState({
    fullname: false,
    dob: false,
    pancard: false,
    adharcard:false,
    fathername:false,
    income: false,
    company: false,
    email: false,
    loanamount: false,
    pincode: false,
  });
  const [openPopup, setOpenPopup] = useState(false); // State for controlling the popup
  const userId = localStorage.getItem("userId");
  const handleNext = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (activeStep === 0) {
      if (!data.fullname) {
        newErrors.fullname = true;
        isValid = false;
      }
      if (!data.dob) {
        newErrors.dob = true;
        isValid = false;
      }
      if (!data.pancard) {
        newErrors.pancard = true;
        isValid = false;
      }
      if (!data.adharcard) {
        newErrors.adharcard = true;
        isValid = false;
        
      }
    } else if (activeStep === 1) {
      if (!data.fathername) {
        newErrors.fathername = true;
        isValid = false;
      }
      if (!data.income) {
        newErrors.income = true;
        isValid = false;
      }
      if (!data.company) {
        newErrors.company = true;
        isValid = false;
      }
      if (!data.email) {
        newErrors.email = true;
        isValid = false;
      }
    } else if (activeStep === 2) {
      if (!data.loanamount) {
        newErrors.loanamount = true;
        isValid = false;
      }
      if (!data.pincode) {
        newErrors.pincode = true;
        isValid = false;
      }
    }

    setErrors(newErrors);
    if (isValid) {
      if (activeStep === steps.length - 1) {
        setOpenPopup(true);
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const datas = new FormData();
    datas.append("full_name", data.fullname);
    datas.append("employ_type", data.employeetype);
    datas.append("loan_type", data.loantype);
    datas.append("dob", data.dob);
    datas.append("pan_number", data.pancard);
    datas.append("adharcard", data.adharcard);
    datas.append("fathername", data.fathername);
    datas.append("company_name", data.company);
    datas.append("email_id", data.email);
    datas.append("loan_amount", data.loanamount);
    datas.append("pin_code", data.pincode);
    datas.append("Monthly_income", data.income);

    try {
      const response = await axios
        .post(
          `https://api.ajfinance.in/api/function.php?action=updatedata&mobile=${userId}`,
          datas
        )
        .then((res) => {
          if (res.data[0].status === "success") {
            setOpen(true);
            // navigate("/");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "pancard") {
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // PAN card pattern
      setErrors({ ...errors, pancard: !panRegex.test(e.target.value) });
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" fontWeight={700}>
                  Personal Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  name="fullname"
                  value={data.fullname}
                  onChange={handleChange}
                  label="Full Name"
                  fullWidth
                  error={errors.fullname} // Shows error styling if validation fails
                  helperText={errors.fullname ? "Full name is required" : ""}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="date"
                  name="dob"
                  value={data.dob}
                  onChange={handleChange}
                  label="Date of birth"
                  fullWidth
                  InputLabelProps={{
                    shrink: true, // Ensures the label stays visible when a value is selected
                  }}
                  error={errors.dob}
                  helperText={errors.dob ? "Date of birth is required" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="pancard"
                  value={data.pancard}
                  onChange={handleChange}
                  label="Pan card number"
                  fullWidth
                  error={errors.pancard}
                  helperText={
                    errors.pancard
                      ? "Invalid PAN number. Format: AAAAA9999A"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                type="number"
                  name="adharcard"
                  value={data.adharcard}
                  onChange={handleChange}
                  label="AAdhar card number"
                  fullWidth
                  error={errors.adharcard}
                  helperText={errors.adharcard ? "AAdhar no. is required" : ""}
                />
              </Grid>
            </Grid>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" fontWeight={700}>
                  Employment Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Your Employment type
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="Salaried"

                    value={data.employeetype}
                    onChange={handleChange}
                    name="employeetype"
                  >
                    <FormControlLabel
                      value="Salaried"
                      control={<Radio />}
                      label="Salaried"
                    />
                    <FormControlLabel
                      value="Self-employed"
                      control={<Radio />}
                      label="Self-employed"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>


              <Grid item xs={12}>
                <TextField
                type="text"
                  name="fathername"
                  value={data.fathername}
                  onChange={handleChange}
                  label="Father Name"
                  fullWidth
                  error={errors.fathername}
                  helperText={errors.fathername ? "Father name is required" : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                type="number"
                  name="income"
                  label="Monthly Income"
                  value={data.income}
                  onChange={handleChange}
          
                  fullWidth
                  error={errors.income}
                  helperText={errors.income ? "Monthly income is required" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  name="company"
                  value={data.company}
                  onChange={handleChange}
                  label="Company name"
               
                  fullWidth
                  error={errors.company}
                  helperText={errors.company ? "Company is required" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                 type="email"
                  name="email"
                  label="Email Id"
                  value={data.email}
                  onChange={handleChange}
                
                  fullWidth
                  error={errors.email}
                  helperText={errors.email ? "Email Id is required" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Do you have income documents (ITR/Salary Slip) available
                    with you?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="no"
                    name="radio-buttons-group"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" fontWeight={700}>
                  Loan type
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={data.loantype}
                    name="loantype"
                    onChange={handleChange}
                  >
                    <MenuItem value="Instant Loan">Instant loan</MenuItem>
                    <MenuItem value="Personal Loan">Personal loan</MenuItem>
                    <MenuItem value="Business Loan">Business loan</MenuItem>
                    <MenuItem value="Home Loan">Home loan</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  name="loanamount"
                  value={data.loanamount}
                  onChange={handleChange}
                  label="Loan Amount"
             
                  fullWidth
                  error={errors.loanamount}
                  helperText={
                    errors.loanamount ? "Loan amount is required" : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  name="pincode"
                  value={data.pincode}
                  onChange={handleChange}
                  label="Current Residential PIN code"
                  error={errors.pincode}
                  helperText={errors.pincode ? "Pincode is required" : ""}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <NavBar name="KYC" />
      <Box style={{ margin: 20 }}>
        <Box>{renderStepContent(activeStep)}</Box>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{
                ...style,
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 id="parent-modal-title" style={{    fontSize: '23px'}}>✅ Application Submitted!</h2>

              <p id="parent-modal-description">
                We have recived your details.
              </p>
              <p style={{marginTop: '0px'}}>It may take up to 24 hours to complete the verification process.</p>
              <Button
                variant="outlined"
                size="medium"
                onClick={() => navigate("/")}
              >
                Close
              </Button>
            </Box>
          </Modal>
          <Grid item xs={12} sm={6}>
            {activeStep === steps.length - 1 ? (
              <Button
                variant="contained"
                fullWidth
                fontWeight={700}
                style={{ color: "#fff", padding: "10px" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                fontWeight={700}
                style={{ color: "#fff", padding: "10px" }}
                onClick={handleNext}
              >
                Continue
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              color="primary"
              disabled={activeStep === 0}
              onClick={handleBack}
              fullWidth
              fontWeight={800}
              style={{ padding: "10px" }}
            >
              Go Back
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Dialog Popup */}
      <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
        <DialogTitle>Form Submission</DialogTitle>
        <DialogContent>
          <Typography>Your form has been submitted successfully!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPopup(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ApplyForm;
