import { Box, Button, Paper, Typography, } from "@mui/material";

import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import NavBar from "./Navbar";
import { formatDate2 } from "./Details";

const DDS = () => {
  const [data, setData] = useState([]);
  const [loding, setLoading] = useState(false);


  const fetchData = async () => {
    const mamberID = localStorage.getItem("mamberId");
    try {
      const response = await axios
        .get(
          `https://utkrishtsociety.com/api/function?action=dds&memberid=${mamberID}`
        )
        .then((res) => {
          setData(res.data);
          setLoading(true);
        });
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="app" data-v-app="" style={{ fontSize: "12px" }}>
      <NavBar name="Loan History" />
      {loding === false ? (
        <Box
          sx={{
            width: "100%",
            marginTop: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          {data.length > 0 ? (
            <div style={{margin:'5px' , marginTop:'10px'}}>
              {data.map((datas, ind) => {
                return (
                  <Paper
                    elevation={3}
                    key={ind}
                    style={{
                     
                      marginBottom: '2px',
                      padding: "10px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                         alignItems:"center"
                      }}
                    >
                      <Typography
                        style={{ fontFamily: "serif", }}
                      >
                        Policy No:<span>{datas.id}</span>{" "}
                      </Typography>
                      <Typography
                        style={{ fontFamily: "serif",}}
                      >
                          Date: <span> { formatDate2(datas.txtpDate)}</span>
                      </Typography>
                    </Box>
                
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems:"center"
                      }}
                    >
            
                      <NavLink style={{textDecoration: "none"}} to={`/policydetails/${datas.id}`} >
                      <Button color="primary">Details</Button>
                      </NavLink>
                      <NavLink style={{textDecoration: "none"}} to={`/statement/${datas.id}`}>
                      <Button color="primary">Statements</Button>
                      </NavLink>
                    </Box>
                  </Paper>
                );
              })}
            </div>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                height: "200px",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontFamily: "serif",
                  fontSize: "16px",
                  fontStyle: "italic",
                }}
              >
                Record Not Found
              </p>
            </Box>
          )}
        </>
      )}{" "}
      {/* <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box> */}
    </div>
  );
};
export default DDS;
