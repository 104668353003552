import { Box, Typography } from "@mui/material";
import NavBar from "./Navbar";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailIcon from "@mui/icons-material/Mail";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";

const Policy = () => {
  return (
    <>
      <NavBar name="Privacy Policy" />
      <Box
        style={{
          display: "flex",
          margin: "15px",
          marginTop: "20px",

          justifyContent: "center",
          flexDirection: "column",
          // alignItems: "center",
        }}
      >
        {/* <Typography variant="h4" color="primary">Privacy Policy</Typography> */}
        <Typography variant="subtitle2" color="primary">
          1. INTRODUCTION{" "}
        </Typography>

        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          This Privacy Policy outlines how AJFinance.in collects, uses, shares,
          and protects personal and non-personal information of its users. The
          goal is to maintain transparency and build trust by clarifying our
          data-handling practices.
        </Typography>

        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}> This policy applies to : </span> Information collected through our website
          (AJFinance.in) and any related services. Data collected from users who
          register, interact, or transact with us online. It does not apply to
          third-party websites or services linked to AJFinance.in.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          Information We Collect
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}>1 Personal Information : </span>  We may collect the following types of personal
          information: Identification Information: Name, date of birth,
          government-issued ID numbers (e.g., PAN, Aadhaar). Contact Details:
          Email address, phone number, mailing address. Financial Data: Bank
          account details, income information, credit history. Authentication
          Information: Passwords, security questions, and answers.
        </Typography>

        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}> Usage Data Automatically collected information includes : </span>
         Technical
          Data: IP address, browser type and version, device type, operating
          system. Behavioral Data: Pages visited, time spent on the website,
          navigation patterns.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          Cookies and Tracking Technologies
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          We use cookies, pixels, and similar technologies to enhance user
          experience and improve functionality.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          3. How We Use Your Information
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}> Service Delivery </span> To process loan applications, verify identity, and
          manage transactions. Communication: To send updates, notifications,
          and promotional offers. Improvement: To enhance website functionality
          and develop new features. Legal and Security: To comply with laws and
          prevent fraud or unauthorized activities.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          4. How We Share Your Information
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
        <span style={{color: '#620101'}}>Service Providers : </span> Payment processing, analytics, and marketing
          support. Legal Compliance: To comply with legal obligations or protect
          against illegal activities. Business Transfers: During mergers,
          acquisitions, or sales of assets.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          5. Cookies Policy
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          We use cookies to enhance user experience. Manage your preferences in
          your browser settings: Essential Cookies: Necessary for website
          functionality. Performance Cookies: Collect data to improve services.
          Marketing Cookies: Deliver relevant advertisements.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          6. Data Security
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          We implement strong security measures, including encryption,
          firewalls, and restricted access, to protect your data.
        </Typography>
        <Typography variant="subtitle2" color="primary">
          7. Contact Information
        </Typography>
        <Typography variant="subtitle1" style={{ padding: "5px", textAlign: 'justify' }}>
          For any privacy-related queries, contact us at: Email:
          help@ajfinance.in Phone:80 6939 0476 Address: B 13 patel market misrod
          bhopal 462026
        </Typography>
      </Box>
    </>
  );
};

export default Policy;
