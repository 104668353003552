import { Box, Typography } from "@mui/material";
import NavBar from "./Navbar";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailIcon from "@mui/icons-material/Mail";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";

const Help = () => {
  return (
    <>
      <NavBar name="Help Desk" />
      <Box
        style={{
          display: "flex",
          margin: "15px",
          marginTop: "40px",

          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography color="primary">CENTRALIZED CUSTOMER DESK NO</Typography>
        <PhoneIphoneIcon
          color="primary"
          style={{
            marginTop: "10px",
            border: "1px solid #39AFEA",
            padding: "5px",
            borderRadius: "18px",
          }}
        />
        <Typography variant="body2" style={{ padding: "5px" }}>
        7803909353
        </Typography>
        {/* <Typography variant="body2">0755-4163306</Typography> */}
        <Box
          style={{
            borderRadius: "15px",
            marginTop: "10px",
          }}
        >
          <MailIcon
            color="primary"
            style={{
              border: "1px solid #39AFEA",
              padding: "5px",
              borderRadius: "18px",
            }}
          />
        </Box>
        <Typography variant="body2">support@ajfinance.in</Typography>
        <Box
          style={{
            borderRadius: "15px",
            marginTop: "10px",
          }}
        >
          <LanguageIcon
            color="primary"
            style={{
              border: "1px solid #39AFEA",
              padding: "5px",
              borderRadius: "18px",
            }}
          />
        </Box>

        {/* <a href="www.utkrishtsociety.in" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}> */}
        <Link to={`https://application.ajfinance.in/`}>
        <Typography variant="body2">https://application.ajfinance.in/</Typography>
        {/* </a> */}
        </Link>
        <Typography
          color="primary"
          style={{ margin: "23px", fontSize: "18px" }}
        >
          The normal working hours from Monday to Saturday are 10:00 am to 05:00
          pm
        </Typography>
        {/* <Typography style={{ fontWeight: "700" }}>
          Get In Touch With Us
        </Typography>
        <Typography>Head Office</Typography>
        <Typography style={{ textTransform: "capitalize", width: "75%" }}>
          Shop no-20, patel market vidisha road bhanpur bhopal (M.P.) 462037
        </Typography> */}
      </Box>
    </>
  );
};

export default Help;
