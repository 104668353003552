import DDS from "./component/DDS";
import HomePage from "./component/HomePage";
import LoginView from "./component/Login";
import { HashRouter, Route, Routes } from "react-router-dom";
import Statement from "./component/Statement";
import HomeLoanView from "./component/HomeLoanView";
import ApplyForm from "./component/ApplyForm";
import BankDetails from "./component/BankDetails";
import Help from "./component/MyDetails";
import Policy from "./component/Policy";
import Terms from "./component/Terms";
import RefundPolicy from "./component/RefundPolicy";



function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/login" element={<LoginView />} />
          <Route exact path="/dds" element={<DDS />} />
          <Route exact path="/homeloanview" element={<HomeLoanView />} />
          <Route exact path="/apply" element={<ApplyForm />} />
          <Route exact path="/bankdetails" element={<BankDetails />} />
          <Route path='/statement/:id' element= {<Statement />} />
          <Route exact path="/help" element={<Help />} />
          <Route exact path="/policy" element={<Policy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/rpolicy" element={<RefundPolicy />} />
         
         </Routes>
      </HashRouter>
    </>
  );
}

export default App;
