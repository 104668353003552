import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavBar from "./Navbar";
import { useNavigate } from "react-router-dom";

const HomeLoanView = () => {
  const history = useNavigate();
  
  return(
  <>
    <NavBar name="Loan" />
    <Grid
      container
      direction="column"
      sx={{ width: "100%" }}
      alignItems="center"
      justifyContent="center"
    >
      <img
        width="100%"
        height="250px"
        style={{ borderRadius: "4px" }}
        src="/images/das7.png"
      />
    </Grid>
    <Button
      variant="contained"
      onClick={()=>history('/apply')}
      style={{
        margin: "18px",
        fontSize: "16px",
        color: "#fff",
        padding: "8px",
        width: "90%",
      }}
    >
      Apply Now
    </Button>

    <Box
      sx={{
        display: "flex",
        marginLeft: "20px",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <FormControlLabel
        control={<Checkbox checked value="remember" color="primary" />}
        label="Send me updates over WhatsApp"
        sx={{ color: "#989898" }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: 1,
          // mt: 9,
        }}
      >
        <Checkbox
        checked
          value="term"
          color="primary"
          sx={{ m: 0, padding: 0, ml: "-2px" }}
        />
        <Typography sx={{ color: "#989898" }}>
          I accept the Terms of Service & Privacy Policy, which includes
          permission to securely share my data/documents with verified partners
          to process my application.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: 1,
          // mt: 9,
        }}
      >
        <Checkbox
        checked
          value="term"
          color="primary"
          sx={{ m: 0, padding: 0, ml: "-2px" }}
        />
        <Typography sx={{ color: "#989898" }}>
          I accept the Terms of Service & Privacy Policy,
        </Typography>
      </Box>
    </Box>
    <Box
      sx={{
        marginTop: 10 ,
        display: "flex",
        marginLeft: "20px",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Typography variant="h4" sx={{ color: "#595757" }}>Frequently Asked Questions</Typography>
    </Box>

    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          // color= "primary.main" 
          sx={{ color: "primary.main" }}
        >
          Who is eligible to apply for a home loan?
        </AccordionSummary>
        <AccordionDetails  sx={{ color: "#989898" }}>
          Eligibility criteria vary by lender, but common factors include: Age:
          Typically 21 to 65 years Employment: Stable job or business Income:
          Sufficient monthly income to repay the loan Credit score: A score
          above 650 is often preferred
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ color: "primary.main" }}
        >
          What documents are required to apply for a home loan?
        </AccordionSummary>
        <AccordionDetails      sx={{ color: "#989898" }}>
          Proof of identity (passport, drivers license) Proof of address
          (utility bills, rental agreement) Proof of income (salary slips, bank
          statements) Property documents Employment verification
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{ color: "primary.main" }}
        >
          Can I prepay my home loan?
        </AccordionSummary>
        <AccordionDetails      sx={{ color: "#989898" }}>
          Yes, most lenders allow prepayment or foreclosure, but some may charge
          prepayment fees, especially for fixed-rate loans. Prepaying helps
          reduce the overall interest burden.
        </AccordionDetails>
      
      </Accordion>
    </div>
  </>
);
}
export default HomeLoanView;
