import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
  } from "@mui/material";
  import NavBar from "./Navbar";
  import { useState } from "react";
  import axios from "axios";
  import { useNavigate } from "react-router-dom";

  const BankDetails = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({
        account_name: "",
        account_number: "",
        account_ifsc:'',
        account_bank: "",
    });
  
    const [openPopup, setOpenPopup] = useState(false); // State for controlling the popup
    const userId = localStorage.getItem("userId");

  
    const handleSubmit =async(e)=>{
      e.preventDefault();
      const datas = new FormData();
      datas.append("account_name", data.account_name);
      datas.append("account_number", data.account_number);
      datas.append("account_ifsc", data.account_ifsc);
      datas.append("account_bank", data.account_bank);    

      try {
        const response = await axios
          .post(`https://api.ajfinance.in/api/function.php?action=bankdetailsupdate&mobile=${userId}`, datas)
          .then((res) => {
  
            if (res.data[0].status === "success") {
                setOpenPopup(true)
            } else {
              console.log('Error')

            }
          });
      } catch (error) {
        console.log("Error", error);
      }
    }
  

    const handleChange = (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    };
  
const handleClose =()=>{
    setOpenPopup(false)
    navigate("/");
}
  
    return (
      <>
        <NavBar name="Bank details" />
        <Box style={{ margin: 20 }} component='form' onSubmit={handleSubmit}>
        <Box >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" fontWeight={700}>
                    Bank Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                  required
                    name="account_bank"
                    value={data.account_bank}
                    onChange={handleChange}
                    placeholder="Enter bank name "
                    label="bank name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    name="account_name"
                    value={data.account_name}
                    onChange={handleChange}
                    placeholder="Enter your account name"
                    label="Enter your account name"
                    fullWidth
                  />
                </Grid>
  
                <Grid item xs={12}>
                  <TextField
                  required
                    type="number"
                    name="account_number"
                    value={data.account_number}
                    onChange={handleChange}
                    placeholder="Enter your account number"
                    label='Enter your account number'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                  required
                    name="account_ifsc"
                    value={data.account_ifsc}
                    onChange={handleChange}
                    placeholder="Enter bank ifsc "
                    label="Enter bank ifsc"
                    fullWidth
                  />
                </Grid>
              
              </Grid>
            </Box>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
          
              <Button
                variant="contained"
                type="submit"
                fullWidth
                fontWeight={700}
                style={{ color: "#fff", padding: "10px" }}
              >
               Submit
              </Button>

            </Grid>
          </Grid>
        </Box>
  
        {/* Dialog Popup */}
        <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
          <DialogTitle>Form Submission</DialogTitle>
          <DialogContent>
            <Typography>Your form has been submitted successfully!</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>handleClose()}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  export default BankDetails;
  